
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import { AdjudicationState } from '@/store/modules/adjudication'
import ClinicalSummary from '@/components/adjudication/ClinicalSummary.vue'
import Spinner from '@/components/Spinner.vue'

export default defineComponent({
    name: 'ClinicalSummaryStatus',
    components: { Spinner, ClinicalSummary },
    props: {
        historical: {
            type: Boolean,
            default: false
        }
    },
    setup() {
        const store = useStore()

        const state = computed<AdjudicationState>(() => {
            return store.state.adjudication.adjudication.type
        })

        function retry() {
            store.dispatch('adjudication/retryFetchAdjudication')
        }

        return {
            state,
            retry
        }
    }
})
