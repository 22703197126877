
import { computed, defineComponent, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import AdjudicationProtocolTable from '@/components/adjudication-protocol/AdjudicationProtocolTable.vue'
import { AdjudicationProtocolUpdate } from '@/models/api/adjudication-protocol'
import { FetchStatus } from '@/store/modules/adjudication-protocol'

export default defineComponent({
    name: 'AdjudicationProtocol',
    components: { AdjudicationProtocolTable },
    setup() {
        const store = useStore()

        const fetchList = () => {
            store.dispatch('adjudicationProtocol/fetchList')
        }

        const fetchStatus = computed<FetchStatus>(() => {
            return store.state.adjudicationProtocol.fetchStatus
        })

        const protocolList = computed(() => {
            return store.state.adjudicationProtocol.protocolList
        })

        function updateProtocol(update: AdjudicationProtocolUpdate) {
            store.dispatch('adjudicationProtocol/updateProtocol', update)
        }

        onBeforeMount(() => fetchList())

        return {
            FetchStatus,
            fetchStatus,
            fetchList,
            protocolList,
            updateProtocol
        }
    }
})
