
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import Modal from '@/components/Modal.vue'
import AdjudicationReview from '@/components/adjudication/review/AdjudicationReview.vue'

export default defineComponent({
    name: 'ReviewStatus',
    components: { AdjudicationReview, Modal },
    setup() {
        const store = useStore()
        const showReview = ref(false)
        const reviewCount = computed(() => store.getters['adjudication/procedureReviewCount'])
        const procedureCount = computed(() => store.getters['adjudication/procedureCount'])
        const allProceduresReviewed = computed(() => reviewCount.value === procedureCount.value)
        const procedureReviewStatus = computed(() => {
            return `${reviewCount.value} / ${procedureCount.value} procedures reviewed`
        })

        function submitReview() {
            showReview.value = true
        }

        function approveAdjudication() {
            store.commit('adjudication/reviewAdjudication', true)
            showReview.value = true
        }

        function denyAdjudication() {
            store.commit('adjudication/reviewAdjudication', false)
            showReview.value = true
        }

        return {
            showReview,
            reviewCount,
            allProceduresReviewed,
            procedureReviewStatus,
            submitReview,
            approveAdjudication,
            denyAdjudication
        }
    }
})
