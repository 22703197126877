
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'TableErrorState',
    props: {
        message: {
            type: String,
            default: 'An error occurred while getting table data...'
        },
        allowRetry: {
            type: Boolean,
            default: false
        }
    },
    emits: ['retry']
})
