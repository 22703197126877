
import { PropType, computed, defineComponent, ref, watch } from 'vue'
import { Procedure } from '@/models/api/adjudication'
import ProcedureSummary from '@/components/adjudication/ProcedureSummary.vue'
import PaginationControl from '@/components/PaginationControl.vue'
import ProcedureSelector from '@/components/adjudication/ProcedureSelector.vue'

export default defineComponent({
    name: 'ProcedureSummaryList',
    components: {
        ProcedureSelector,
        PaginationControl,
        ProcedureSummary
    },
    props: {
        historical: {
            type: Boolean,
            default: false
        },
        procedures: {
            type: Object as PropType<Procedure[]>,
            default: () => []
        }
    },
    setup(props) {
        const count = computed(() => props.procedures.length)
        const currentIndex = ref(0)

        watch(
            () => props.procedures,
            () => (currentIndex.value = 0)
        )

        const showPrevious = () => {
            currentIndex.value = Math.max(0, currentIndex.value - 1)
        }

        const showNext = () => {
            currentIndex.value = Math.min(count.value - 1, currentIndex.value + 1)
        }

        return {
            count,
            currentIndex,
            showPrevious,
            showNext
        }
    }
})
