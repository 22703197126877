
import { computed, defineComponent } from 'vue'

export default defineComponent({
    name: 'Recommendation',
    props: {
        status: {
            type: String,
            default: ''
        }
    },
    setup: props => ({
        classList: computed(() => [
            'recommendation-status',
            'font-bold',
            props.status.includes('Approve') || props.status.includes('A1')
                ? 'text-green-700'
                : 'text-red-700'
        ])
    })
})
