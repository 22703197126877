import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "recommendation-rate w-full" }
const _hoisted_2 = { class: "flex justify-center items-center font-bold text-2xl xl:text-3xl text-white h-full" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "flex justify-center items-center font-bold text-2xl xl:text-3xl text-white h-full" }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "recommendation-acceptance bg-green-600 h-16",
      style: _normalizeStyle(_ctx.acceptanceRateWidth)
    }, [
      _createElementVNode("div", _hoisted_2, [
        (_ctx.fraction >= 0.3)
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.acceptancePercentage) + "% Accepted", 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_4, "..."))
      ])
    ], 4),
    _createElementVNode("div", {
      class: "recommendation-declined bg-green-300 h-16",
      style: _normalizeStyle(_ctx.declineRateWidth)
    }, [
      _createElementVNode("div", _hoisted_5, [
        (_ctx.fraction <= 0.7)
          ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.declinePercentage) + "% Declined", 1))
          : (_openBlock(), _createElementBlock("span", _hoisted_7, "..."))
      ])
    ], 4)
  ]))
}