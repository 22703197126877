import * as t from 'io-ts'

export const KeycloakUser = t.intersection([
    t.type({
        id: t.string,
        enabled: t.boolean
    }),
    t.partial({
        username: t.union([t.string, t.null]),
        firstName: t.union([t.string, t.null]),
        lastName: t.union([t.string, t.null]),
        role: t.union([t.string, t.null])
    })
])

export type KeycloakUser = t.TypeOf<typeof KeycloakUser>

export const KeycloakUserList = t.array(KeycloakUser)

export type KeycloakUserList = t.TypeOf<typeof KeycloakUserList>

export interface KeycloakUserUpdate {
    enabled: boolean
    role: string | null
}
