
import { computed, defineComponent, PropType, ref } from 'vue'

export interface SelectionOption {
    /**
     * Value emitted from component if option is selected.
     */
    value: string

    /**
     * Name of the option displayed in the UI.
     */
    name: string

    /**
     * List of CSS classes to apply to the selected option.
     */
    classes: string[]
}

export default defineComponent({
    name: 'Selection',
    props: {
        options: {
            type: Array as PropType<SelectionOption[]>,
            default: () => []
        },
        value: {
            type: String,
            required: true
        }
    },
    emits: ['update:value'],
    setup(props, context) {
        const selectElement = ref<HTMLSelectElement | null>(null)

        const selectedOption = computed(() => {
            return props.options.find(o => o.value === props.value)
        })

        const classes = computed(() => {
            const baseClasses = [
                'selection',
                'px-6',
                'py-1',
                'rounded',
                'font-medium',
                'appearance-none',
                'cursor-pointer',
                'text-center'
            ]

            const selectedClasses = selectedOption.value?.classes ?? []
            return [baseClasses, ...selectedClasses]
        })

        function selectOption(option: string) {
            context.emit('update:value', option)

            // Prevent selected value from updating until the prop re-binds.
            if (selectElement.value) {
                selectElement.value.value = props.value
            }
        }

        return {
            classes,
            selectOption,
            selectElement
        }
    }
})
