
import { defineComponent, watchEffect } from 'vue'

export default defineComponent({
    name: 'Modal',
    props: {
        active: {
            type: Boolean,
            default: false
        }
    },
    emits: ['update:active'],
    setup(props, context) {
        watchEffect(() => {
            // While the modal is active, we want to disable scrolling for
            // content that exists beneath the modal.
            document.body.style.overflow = props.active ? 'hidden' : ''
        })

        function toggleModal() {
            context.emit('update:active', !props.active)
        }

        return { toggleModal }
    }
})
