
import { PropType, defineComponent, computed } from 'vue'
import { Procedure } from '@/models/api/adjudication'

export default defineComponent({
    name: 'ProcedureSelector',
    props: {
        procedures: {
            type: Object as PropType<Procedure[]>,
            default: () => []
        },
        index: {
            type: Number,
            default: 0
        }
    },
    emits: ['update:index'],
    setup(props, context) {
        const count = computed(() => {
            return props.procedures.length
        })

        function selectProcedure(event: InputEvent) {
            const element = event.target as HTMLInputElement
            context.emit('update:index', Number(element.value))
        }

        return {
            count,
            selectProcedure
        }
    }
})
