import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "statistic-card flex flex-col align-center justify-center px-8 xl:px-16 py-8 text-white rounded-lg" }
const _hoisted_2 = { class: "statistic-value text-center font-bold text-4xl xl:text-7xl" }
const _hoisted_3 = { class: "statistic-label text-center font-bold text-2xl xl:text-2xl" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.value), 1),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.label), 1)
  ]))
}