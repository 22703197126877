
import { computed, defineComponent, onBeforeMount } from 'vue'
import { useStore } from 'vuex'
import { KeycloakUserUpdate } from '@/models/api/user-management'
import RoleTable from '@/components/user-management/RoleTable.vue'

export default defineComponent({
    name: 'UserManagement',
    components: {
        RoleTable
    },
    setup() {
        const store = useStore()
        const users = computed(() => store.getters['userManagement/users'])
        const payerId = computed(() => store.getters['account/clientId'])

        onBeforeMount(() => {
            store.dispatch('userManagement/fetchUsers')
        })

        function updateUserRole(payload: { id: string; role: string }) {
            const updatePayload: KeycloakUserUpdate = {
                enabled: true,
                role: payload.role
            }

            if (payload.role === 'disabled') {
                updatePayload.enabled = false
                updatePayload.role = null
            }

            store.dispatch('userManagement/updateUser', {
                id: payload.id,
                update: updatePayload
            })
        }

        return {
            payerId,
            users,
            updateUserRole
        }
    }
})
