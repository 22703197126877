import { createElementVNode as _createElementVNode, renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5efb9757"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "modal fixed top-0 left-0 h-full w-full flex justify-center items-center"
}
const _hoisted_2 = { class: "modal-container bg-white mx-auto rounded shadow-lg z-50" }
const _hoisted_3 = { class: "modal-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.active)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", {
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleModal && _ctx.toggleModal(...args))),
                class: "modal-overlay absolute h-full w-full bg-gray-900 opacity-50"
              }),
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("div", _hoisted_3, [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ])
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 3
    })
  ]))
}