import Swal from 'sweetalert2'

export enum AlertType {
    SUCCESS = 'success',
    ERROR = 'error',
    INFO = 'info'
}

export type ToastConfiguration = Partial<{
    duration: number
    icon: AlertType
}>

export function toast(message: string, config: ToastConfiguration = {}) {
    const defaultConfig = {
        duration: 3000
    }

    const settings = {
        ...defaultConfig,
        ...config
    }

    Swal.fire({
        icon: settings.icon,
        showConfirmButton: false,
        showClass: { popup: 'toast-fade-in' },
        timer: settings.duration,
        timerProgressBar: true,
        title: message,
        toast: true,
        position: 'bottom-right'
    })
}

export type ConfirmationConfiguration = Partial<{
    title: string
    duration: number
    icon: AlertType
    confirmText: string
}>

export async function confirm(message: string, config: ConfirmationConfiguration = {}) {
    const defaultConfig = {
        confirmText: 'OK',
        cancelText: 'Cancel',
        duration: 3000
    }

    const settings = {
        ...defaultConfig,
        ...config
    }

    const result = await Swal.fire({
        title: settings.title,
        text: message,
        icon: settings.icon,
        confirmButtonText: settings.confirmText,
        cancelButtonText: settings.cancelText,
        showCancelButton: true,
        reverseButtons: true,
        showClass: { popup: 'confirm-fade-in' }
    })

    return result.isConfirmed
}
