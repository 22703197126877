
import { computed, defineComponent } from 'vue'

export default defineComponent({
    name: 'PaginationControl',
    props: {
        disabled: {
            type: Boolean,
            default: false
        },
        faIcon: {
            type: String,
            default: 'question'
        }
    },
    emits: ['click'],
    setup(props, context) {
        const divClasses = computed(() =>
            !props.disabled
                ? ['w-8', 'bg-gray-100', 'hover:bg-gray-300', 'cursor-pointer']
                : ['w-8', 'bg-gray-100', 'opacity-30', 'cursor-not-allowed']
        )

        const iconClasses = computed(() => [
            'flex',
            'justify-center',
            'items-center',
            'h-full',
            'w-full',
            'fas',
            'fa-' + props.faIcon
        ])

        function handleClick() {
            if (!props.disabled) {
                context.emit('click')
            }
        }

        return {
            divClasses,
            iconClasses,
            handleClick
        }
    }
})
