
import { computed, defineComponent, PropType } from 'vue'
import TableList from '@/components/table-list/TableList.vue'
import TableHeader from '@/components/table-list/TableHeader.vue'
import TableRow from '@/components/table-list/TableRow.vue'
import TableFetchState from '@/components/table-list/TableFetchState.vue'
import TableErrorState from '@/components/table-list/TableErrorState.vue'
import Tooltip from '@/components/Tooltip.vue'
import Selection, { SelectionOption } from '@/components/Selection.vue'
import * as array from 'fp-ts/Array'

import {
    AdjudicationProtocol,
    AdjudicationProtocolList,
    AdjudicationProtocolUpdate,
    bySubmissionCount
} from '@/models/api/adjudication-protocol'
import { FetchStatus } from '@/store/modules/adjudication-protocol'

export default defineComponent({
    name: 'AdjudicationProtocolTable',
    components: {
        TableErrorState,
        TableFetchState,
        Selection,
        TableRow,
        TableHeader,
        TableList,
        Tooltip
    },
    props: {
        protocolList: {
            type: Array as PropType<AdjudicationProtocolList>,
            default: () => []
        },
        fetchStatus: {
            type: String as PropType<FetchStatus>,
            required: true
        }
    },
    emits: ['update', 'retry'],
    setup(props, context) {
        const autoAdjudicationsOptions: SelectionOption[] = [
            {
                value: 'not_set',
                name: 'Not Set',
                classes: ['bg-gray-300', 'text-gray-600']
            },
            {
                value: 'manual',
                name: 'Manual',
                classes: ['bg-red-600', 'text-white']
            },
            {
                value: 'auto',
                name: 'Auto',
                classes: ['bg-green-600', 'text-white']
            }
        ]

        function getSelectedValue(protocol: AdjudicationProtocol): string {
            switch (protocol.status) {
                case true:
                    return 'auto'
                case false:
                    return 'manual'
                default:
                    return 'not_set'
            }
        }

        function updateProtocol(protocol: AdjudicationProtocol, status: string) {
            const updatedStatus = status === 'not_set' ? null : status === 'auto'
            const update: AdjudicationProtocolUpdate = {
                payerId: protocol.payerId,
                planId: protocol.planId,
                policyId: protocol.policyId,
                procedureCode: protocol.procedureCode,
                group: null,
                isAutoAdjudicated: updatedStatus
            }

            context.emit('update', update)
        }

        const sortedByCount = computed(() => {
            return array
                .sort(bySubmissionCount)(props.protocolList)
                .reverse()
        })

        return {
            FetchStatus,
            autoAdjudicationsOptions,
            getSelectedValue,
            updateProtocol,
            sortedByCount
        }
    }
})
