import axios from 'axios'
import * as either from 'fp-ts/Either'
import prettyReporter from 'io-ts-reporters'
import {
    AdjudicationProtocolList,
    AdjudicationProtocolListResponse,
    AdjudicationProtocolUpdate
} from '@/models/api/adjudication-protocol'
import { AlertType, toast } from '@/modules/alerts'

export enum FetchStatus {
    FETCHING = 'FETCHING',
    SUCCESS = 'SUCCESS',
    FAILURE = 'FAILURE'
}

interface State {
    protocolList: AdjudicationProtocolList
    fetchStatus: FetchStatus
}

const state: State = {
    protocolList: [],
    fetchStatus: FetchStatus.SUCCESS
}

const mutations = {
    setProtocolList(state: State, list: AdjudicationProtocolList) {
        state.protocolList = list
    },

    setFetchStatus(state: State, status: FetchStatus) {
        state.fetchStatus = status
    }
}

const actions = {
    async fetchList({ commit }: { commit: Function }) {
        try {
            commit('setFetchStatus', FetchStatus.FETCHING)
            const response = await axios.get(`/api/v1/protocols`)
            const responseDecoded = AdjudicationProtocolListResponse.decode(response.data)

            if (either.isRight(responseDecoded)) {
                const { protocols } = responseDecoded.right
                commit('setProtocolList', protocols)
                commit('setFetchStatus', FetchStatus.SUCCESS)
            } else {
                console.log(prettyReporter.report(responseDecoded))
                commit('setFetchStatus', FetchStatus.FAILURE)
            }
        } catch (e) {
            commit('setFetchStatus', FetchStatus.FAILURE)
        }
    },

    async updateProtocol({ dispatch }: { dispatch: Function }, update: AdjudicationProtocolUpdate) {
        try {
            await axios.patch(`/api/v1/protocols`, update)
            await dispatch('fetchList')
            toast('Adjudication protocol updated!', {
                icon: AlertType.SUCCESS
            })
        } catch (e) {
            toast('We are unable to update the policy protocol at this time', {
                icon: AlertType.ERROR
            })
        }
    }
}

export default {
    state,
    mutations,
    actions
}
