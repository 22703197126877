import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "submission-information" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SummaryProperty = _resolveComponent("SummaryProperty")!
  const _component_Recommendation = _resolveComponent("Recommendation")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SummaryProperty, {
      name: "Reference #",
      value: _ctx.value.authorizationId
    }, null, 8, ["value"]),
    _createVNode(_component_SummaryProperty, {
      name: "Submission Date",
      value: _ctx.value.createdDate
    }, null, 8, ["value"]),
    _createVNode(_component_SummaryProperty, { name: "Recommendation" }, {
      default: _withCtx(() => [
        _createVNode(_component_Recommendation, {
          status: _ctx.value.recommendationStatus
        }, null, 8, ["status"])
      ]),
      _: 1
    }),
    (_ctx.historical)
      ? (_openBlock(), _createBlock(_component_SummaryProperty, {
          key: 0,
          class: "recommendation-status",
          name: "Clinical Review"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Recommendation, {
              status: _ctx.value.status
            }, null, 8, ["status"])
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.value.approvalPeriod)
      ? (_openBlock(), _createBlock(_component_SummaryProperty, {
          key: 1,
          name: "Approval Period",
          value: _ctx.value.approvalPeriod
        }, null, 8, ["value"]))
      : _createCommentVNode("", true)
  ]))
}