
import { PropType, defineComponent } from 'vue'
import { SubmissionInformation } from '@/models/view/SubmissionInformation'
import Recommendation from '@/components/adjudication/Recommendation.vue'
import SummaryProperty from '@/components/adjudication/SummaryProperty.vue'

export default defineComponent({
    name: 'SubmissionInformation',
    components: {
        Recommendation,
        SummaryProperty
    },
    props: {
        value: {
            type: Object as PropType<SubmissionInformation>,
            required: true
        },
        historical: {
            type: Boolean,
            default: false
        }
    }
})
