
import { useStore } from 'vuex'
import { PropType, defineComponent, ref, computed } from 'vue'
import { Adjudication, PolicyCriterion, Procedure } from '@/models/api/adjudication'
import SummaryProperty from '@/components/adjudication/SummaryProperty.vue'
import Recommendation from '@/components/adjudication/Recommendation.vue'
import Modal from '@/components/Modal.vue'
import ProcedureCriteria from '@/components/adjudication/ProcedureCriteria.vue'

function formatFrequency(procedure: Procedure): string {
    return `${procedure.approvalFrequencyValue} ${procedure.approvalFrequencyType}`
}

function formatApproval(approved: boolean): string {
    if (approved) {
        return 'Approve'
    } else {
        return 'Deny'
    }
}
export default defineComponent({
    name: 'ProcedureSummary',
    components: { ProcedureCriteria, Modal, Recommendation, SummaryProperty },
    props: {
        procedureIndex: {
            type: Number,
            required: true
        },
        procedure: {
            type: Object as PropType<Procedure>,
            required: true
        },
        historical: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const store = useStore()

        const clinicalReview = computed<boolean | null>(() =>
            store.getters['adjudication/procedureReviewStatus'](props.procedureIndex)
        )

        const clinicalReviewText = computed(() => {
            return clinicalReview.value ? 'Approve' : 'Deny'
        })

        const approvalCriteria = computed<PolicyCriterion | undefined>(() =>
            props.procedure.criteria.find(c => c.evaluated)
        )

        const otherCriteria = computed<PolicyCriterion[]>(() =>
            props.procedure.criteria.filter(c => !c.evaluated)
        )

        const showOtherCriteria = ref(false)
        function toggleOtherCriteria() {
            showOtherCriteria.value = !showOtherCriteria.value
        }

        const adjudication = computed<Adjudication | null>(() => {
            return store.state.adjudication.adjudication
        })

        return {
            clinicalReview,
            clinicalReviewText,
            formatFrequency,
            approvalCriteria,
            otherCriteria,
            showOtherCriteria,
            toggleOtherCriteria,
            adjudication,
            formatApproval
        }
    }
})
