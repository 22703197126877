import axios from 'axios'
import * as either from 'fp-ts/Either'
import prettyReporter from 'io-ts-reporters'
import { AdjudicationStatistics } from '@/models/api/adjudication-statistics'

/**
 * Fetches the dashboard statistics.
 */
export async function fetchDashboardStatistics(): Promise<AdjudicationStatistics> {
    const response = await axios.get('/api/v1/reporting')
    const validation = AdjudicationStatistics.decode(response.data)

    if (either.isLeft(validation)) {
        const errors = prettyReporter.report(validation)
        throw new Error('unexpected response from server:\n' + errors)
    }

    return response.data
}
