
import { defineComponent, PropType } from 'vue'
import { KeycloakUser } from '@/models/view/user-management'
import TableRow from '@/components/table-list/TableRow.vue'
import TableList from '@/components/table-list/TableList.vue'
import TableHeader from '@/components/table-list/TableHeader.vue'
import Selection, { SelectionOption } from '@/components/Selection.vue'

export default defineComponent({
    name: 'RoleTable',
    components: {
        Selection,
        TableHeader,
        TableList,
        TableRow
    },
    props: {
        users: {
            type: Array as PropType<KeycloakUser[]>,
            default: () => []
        }
    },
    emits: ['update'],
    setup(props, context) {
        const roles: SelectionOption[] = [
            {
                value: 'disabled',
                name: 'Disabled',
                classes: ['bg-red-600', 'text-white']
            },
            {
                value: 'user',
                name: 'User',
                classes: ['bg-green-600', 'text-white']
            },
            {
                value: 'admin',
                name: 'Admin',
                classes: ['bg-blue-600', 'text-white']
            }
        ]

        function updateRole(id: string, role: string) {
            context.emit('update', { id, role })
        }

        return {
            roles,
            updateRole
        }
    }
})
