
import { computed, defineComponent, inject, ref } from 'vue'
import { Emitter } from 'mitt'
import { nanoid } from 'nanoid'
import { EMITTER, TreeEvent } from './events'
import Expandable from './Expandable.vue'
import TreeArray from './TreeArray.vue'
import TreeProperty from './TreeProperty.vue'
import TreePropertyGrid from './TreePropertyGrid.vue'

export default defineComponent({
    name: 'TreeObject',
    components: {
        Expandable,
        TreeArray,
        TreeProperty,
        TreePropertyGrid
    },
    props: {
        parent: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: false
        },
        subtitle: {
            type: String,
            required: false
        },
        value: {
            type: Object,
            required: true
        }
    },
    setup(props) {
        const id = nanoid()
        const qualifiedId = computed(() => [props.parent, id].join('.'))
        const eventBus = inject<Emitter>(EMITTER)
        const expandable = ref<InstanceType<typeof Expandable> | null>(null)

        eventBus?.on('*', (source, action: TreeEvent) => {
            if (qualifiedId.value.includes(source as string)) {
                switch (action) {
                    case TreeEvent.EXPAND_DESCENDANTS:
                        expandable.value?.expand()
                        break

                    case TreeEvent.COLLAPSE_DESCENDANTS:
                        expandable.value?.collapse()
                        break
                }
            }
        })

        /**
         * Converts the value prop into a list of enumerable key-value entries
         * and filters the elements with the given predicate.
         */
        function filterEntries<V>(predicate: <V>(e: [string, V]) => boolean) {
            const entries = Object.entries(props.value)
            return entries.filter(([_, v]) => predicate(v))
        }

        // Type guards for the different kinds of value types.
        const isArray = Array.isArray
        const isObject = (x: unknown) => typeof x === 'object' && !isArray(x) && x != null
        const isProperty = (x: unknown) => typeof x !== 'object' || x == null

        // Partition the list of key-value pairs by value type.
        const properties = computed(() => filterEntries(isProperty))
        const objects = computed(() => filterEntries(isObject))
        const arrays = computed(() => filterEntries(isArray))

        return {
            qualifiedId,
            expandable,
            properties,
            objects,
            arrays
        }
    }
})
