
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'SummaryProperty',
    props: {
        name: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: false
        }
    }
})
