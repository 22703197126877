
import { defineComponent, PropType } from 'vue'
import { PolicyCriterion } from '@/models/api/adjudication'

export default defineComponent({
    name: 'ProcedureCriteriaList',
    emits: ['update:selected'],
    props: {
        selected: {
            type: String,
            required: false
        },
        criteria: {
            type: Array as PropType<PolicyCriterion[]>,
            default: () => []
        },
        historical: {
            type: Boolean,
            default: false
        }
    },
    setup(props, context) {
        function selectCriterion(name: string) {
            if (name === props.selected) {
                context.emit('update:selected', null)
            } else {
                context.emit('update:selected', name)
            }
        }

        function getRowStyles(name: string) {
            return ['cursor-pointer', name === props.selected ? 'bg-blue-200' : null]
        }

        return {
            selectCriterion,
            getRowStyles
        }
    }
})
