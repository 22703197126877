
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
    name: 'Expandable',
    props: {
        title: {
            type: String,
            default: ''
        },
        subtitle: {
            type: String,
            required: false
        },
        open: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const show = ref(props.open)

        // Show the slot content.
        function expand() {
            show.value = true
        }

        // Hide the slot content.
        function collapse() {
            show.value = false
        }

        // Toggle between showing and hiding the expandable content.
        function toggleShow() {
            show.value = !show.value
        }

        // Rotate the chevron icon when the section is expanded (visible).
        const indicatorClass = computed(() => {
            return show.value ? ['fa-rotate-90'] : []
        })

        // Convert camelCase keys to Title Case.
        const formattedTitle = computed(() => {
            return props.title
                .replace(/((?<![A-Z])[A-Z])/g, ' $1')
                .replace(/^./, s => s.toUpperCase())
                .trim()
        })

        return {
            formattedTitle,
            show,
            expand,
            collapse,
            toggleShow,
            indicatorClass
        }
    }
})
