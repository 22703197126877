import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from '@/store'
import ActivateAccount from '@/views/ActivateAccount.vue'
import Adjudication from '../views/Adjudication.vue'
import Dashboard from '../views/Dashboard.vue'
import HistoricalAdjudication from '@/views/HistoricalAdjudication.vue'
import UserManagement from '@/views/UserManagement.vue'
import AdjudicationProtocol from '@/views/AdjudicationProtocol.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'Home',
        component: Dashboard
    },
    {
        path: '/adjudications',
        name: 'Adjudication',
        component: Adjudication
    },
    {
        path: '/protocol',
        meta: { isAdminRoute: true },
        name: 'AdjudicationProtocol',
        component: AdjudicationProtocol
    },
    {
        path: '/historical',
        name: 'HistoricalAdjudication',
        component: HistoricalAdjudication
    },
    {
        path: '/activate',
        name: 'ActivateAccount',
        component: ActivateAccount
    },
    {
        path: '/management',
        meta: { isAdminRoute: true },
        name: 'UserManagement',
        component: UserManagement
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.meta.isAdminRoute && !store.getters['account/isAdmin']) {
        next('/')
    } else {
        next()
    }
})

router.beforeEach((to, from, next) => {
    if (to.name === 'ActivateAccount' && !store.getters['account/isDisabled']) {
        next('/')
    } else if (to.name !== 'ActivateAccount' && store.getters['account/isDisabled']) {
        next({ name: 'ActivateAccount' })
    } else {
        next()
    }
})

export default router
