import store from './store'
import Keycloak from 'keycloak-js'
import 'tailwindcss/tailwind.css'
import { AlertType, toast } from '@/modules/alerts'

const keycloak = Keycloak('./config/keycloak.json')

export function logout() {
    return keycloak.logout()
}

function checkClientIdConfiguration(keycloak: Keycloak.KeycloakInstance) {
    if (keycloak?.idTokenParsed?.client_id == null) {
        toast('Your user account has not been configured.', {
            icon: AlertType.ERROR
        })
        logout()
    }
}

function saveKeycloakToken(keycloak: Keycloak.KeycloakInstance) {
    store.commit('account/login', {
        username: keycloak.profile?.username,
        roles: keycloak?.resourceAccess?.['myndauth-ui']?.roles ?? [],
        token: keycloak.token,
        clientId: keycloak?.idTokenParsed?.client_id,
        authServerUrl: keycloak.authServerUrl
    })
}

export async function init() {
    keycloak.onTokenExpired = async () => {
        try {
            const isTokenRefreshed = await keycloak.updateToken(300)
            if (isTokenRefreshed) {
                saveKeycloakToken(keycloak)
                checkClientIdConfiguration(keycloak)
            }
        } catch {
            toast('Your login session has expired.', {
                icon: AlertType.ERROR
            })
            logout()
        }
    }

    const isAuthenticated = await keycloak.init({
        onLoad: 'login-required'
    })

    if (!isAuthenticated) {
        store.commit('account/logout')
        window.location.reload()
        return
    }

    saveKeycloakToken(keycloak)
    checkClientIdConfiguration(keycloak)
}
