import { createStore, Store } from 'vuex'
import account from './modules/account'
import adjudication from './modules/adjudication'
import adjudicationProtocol from './modules/adjudication-protocol'
import userManagement from './modules/user-management'

export default createStore({
    state: {},
    mutations: {},
    actions: {},
    modules: {
        adjudication: {
            namespaced: true,
            ...adjudication
        },
        adjudicationProtocol: {
            namespaced: true,
            ...adjudicationProtocol
        },
        account: {
            namespaced: true,
            ...account
        },
        userManagement: {
            namespaced: true,
            ...userManagement
        }
    }
}) as Store<any>
