import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d46786e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "expandable" }
const _hoisted_2 = { class: "capitalize ml-2" }
const _hoisted_3 = {
  key: 0,
  class: "ml-2 uppercase text-gray-400 text-sm"
}
const _hoisted_4 = { class: "expandable-content ml-4 my-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.title)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "expandable-title cursor-pointer bg-gray-100 rounded px-4 py-2",
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleShow && _ctx.toggleShow(...args)))
        }, [
          _createElementVNode("i", {
            class: _normalizeClass(["fas fa-chevron-right transition", _ctx.indicatorClass])
          }, null, 2),
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.formattedTitle), 1),
          (_ctx.subtitle)
            ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.subtitle), 1))
            : _createCommentVNode("", true)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", _hoisted_4, [
          _renderSlot(_ctx.$slots, "default", {}, undefined, true)
        ], 512), [
          [_vShow, !_ctx.title || _ctx.show]
        ])
      ]),
      _: 3
    })
  ]))
}