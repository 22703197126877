import * as t from 'io-ts'

export const AdjudicationStatistics = t.type({
    totalInQueue: t.number,
    totalResponded: t.number,
    totalMembers: t.number,
    totalProcedures: t.number,
    followedRecommendations: t.number
})

export type AdjudicationStatistics = t.TypeOf<typeof AdjudicationStatistics>
