import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "procedure-summary-list",
  class: "flex"
}
const _hoisted_2 = { class: "flex-grow w-full p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PaginationControl = _resolveComponent("PaginationControl")!
  const _component_ProcedureSelector = _resolveComponent("ProcedureSelector")!
  const _component_ProcedureSummary = _resolveComponent("ProcedureSummary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PaginationControl, {
      id: "procedure-prev",
      onClick: _ctx.showPrevious,
      "fa-icon": "chevron-left",
      disabled: _ctx.currentIndex === 0
    }, null, 8, ["onClick", "disabled"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ProcedureSelector, {
        index: _ctx.currentIndex,
        "onUpdate:index": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentIndex) = $event)),
        procedures: _ctx.procedures
      }, null, 8, ["index", "procedures"]),
      (_ctx.procedures.length > 0)
        ? (_openBlock(), _createBlock(_component_ProcedureSummary, {
            key: 0,
            procedure: _ctx.procedures[_ctx.currentIndex],
            "procedure-index": _ctx.currentIndex,
            historical: _ctx.historical
          }, null, 8, ["procedure", "procedure-index", "historical"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_PaginationControl, {
      id: "procedure-next",
      onClick: _ctx.showNext,
      "fa-icon": "chevron-right",
      disabled: _ctx.currentIndex === _ctx.count - 1
    }, null, 8, ["onClick", "disabled"])
  ]))
}