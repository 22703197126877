import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "tree-object mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TreeProperty = _resolveComponent("TreeProperty")!
  const _component_TreePropertyGrid = _resolveComponent("TreePropertyGrid")!
  const _component_TreeObject = _resolveComponent("TreeObject", true)!
  const _component_TreeArray = _resolveComponent("TreeArray")!
  const _component_Expandable = _resolveComponent("Expandable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Expandable, {
      ref: "expandable",
      parent: _ctx.qualifiedId,
      title: _ctx.name,
      subtitle: _ctx.subtitle
    }, {
      default: _withCtx(() => [
        (_ctx.properties.length > 0)
          ? (_openBlock(), _createBlock(_component_TreePropertyGrid, {
              key: 0,
              class: "mb-4"
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.properties, ([k, v]) => {
                  return (_openBlock(), _createBlock(_component_TreeProperty, {
                    name: k,
                    value: String(v),
                    key: k
                  }, null, 8, ["name", "value"]))
                }), 128))
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.objects, ([k, v]) => {
          return (_openBlock(), _createBlock(_component_TreeObject, {
            parent: _ctx.qualifiedId,
            name: k,
            subtitle: "object",
            value: v,
            key: k
          }, null, 8, ["parent", "name", "value"]))
        }), 128)),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.arrays, ([k, array]) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "tree-array my-4",
            key: k
          }, [
            _createVNode(_component_TreeArray, {
              parent: _ctx.qualifiedId,
              name: k,
              value: array
            }, null, 8, ["parent", "name", "value"])
          ]))
        }), 128))
      ]),
      _: 1
    }, 8, ["parent", "title", "subtitle"])
  ]))
}