import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex w-full"
}
const _hoisted_2 = {
  key: 1,
  class: "m-auto font-bold"
}
const _hoisted_3 = {
  key: 1,
  class: "flex space-between w-full"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdjudicationReview = _resolveComponent("AdjudicationReview")!
  const _component_Modal = _resolveComponent("Modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.reviewCount > 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_ctx.allProceduresReviewed)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.submitReview && _ctx.submitReview(...args))),
                class: "button flex-1 font-bold text-white bg-blue-500 px-4 py-1 rounded cursor-pointer"
              }, " Submit Review "))
            : (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.procedureReviewStatus), 1))
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("button", {
            onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.denyAdjudication && _ctx.denyAdjudication(...args))),
            class: "deny-adjudication button flex-1 font-bold text-white bg-red-500 px-4 py-1 rounded cursor-pointer"
          }, " Deny "),
          _createElementVNode("button", {
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.approveAdjudication && _ctx.approveAdjudication(...args))),
            class: "approve-adjudication button flex-1 font-bold text-white bg-green-500 px-4 py-1 rounded admin-pointer ml-4"
          }, " Approve ")
        ])),
    _createVNode(_component_Modal, {
      active: _ctx.showReview,
      "onUpdate:active": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.showReview) = $event))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_AdjudicationReview, {
          onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showReview = false)),
          style: {"height":"90vh","width":"80vw"}
        })
      ]),
      _: 1
    }, 8, ["active"])
  ], 64))
}