
import {PropType, defineComponent, ref} from 'vue'
import {SummaryPolicy} from "@/models/api/adjudication";
import Tooltip from "@/components/Tooltip.vue";

export default defineComponent({
    name: 'PolicyListItem',
    components: {Tooltip},
    props: {
        policy: {
            type: Object as PropType<SummaryPolicy>,
            required: true
        }
    },
    setup() {
        const showPolicyName = ref(false)

        return {
            showPolicyName
        }
    }
})
