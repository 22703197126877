import { pipe } from 'fp-ts/function'
import { contramap, Ord, ordNumber } from 'fp-ts/Ord'
import * as t from 'io-ts'

export const AdjudicationProtocol = t.type({
    payerId: t.string,
    planId: t.union([t.string, t.null]),
    planName: t.union([t.string, t.null]),
    procedureCode: t.union([t.string, t.null]),
    policyId: t.union([t.string, t.null]),
    policyName: t.union([t.string, t.null]),
    group: t.union([t.string, t.null]),
    submissionCount: t.number,
    acceptanceRate: t.number,
    status: t.union([t.boolean, t.null])
})

export type AdjudicationProtocol = t.TypeOf<typeof AdjudicationProtocol>

export const AdjudicationProtocolList = t.array(AdjudicationProtocol)

export type AdjudicationProtocolList = t.TypeOf<typeof AdjudicationProtocolList>

export const AdjudicationProtocolListResponse = t.type({
    nextPageToken: t.string,
    protocols: AdjudicationProtocolList
})

export type AdjudicationProtocolListResponse = t.TypeOf<typeof AdjudicationProtocolListResponse>

export interface AdjudicationProtocolUpdate {
    payerId: string
    planId: string | null
    procedureCode: string | null
    policyId: string | null
    group: string | null
    isAutoAdjudicated: boolean | null
}

// Define a total-ordering on AdjudicationProtocol using submissionCount.
export const bySubmissionCount: Ord<AdjudicationProtocol> = pipe(
    ordNumber,
    contramap(p => p.submissionCount)
)
