import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "adjudication-protocol",
  class: "m-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdjudicationProtocolTable = _resolveComponent("AdjudicationProtocolTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AdjudicationProtocolTable, {
      "protocol-list": _ctx.protocolList,
      "fetch-status": _ctx.fetchStatus,
      onUpdate: _ctx.updateProtocol,
      onRetry: _ctx.fetchList
    }, null, 8, ["protocol-list", "fetch-status", "onUpdate", "onRetry"])
  ]))
}