
import { defineComponent, PropType } from 'vue'
import { ProcedureReview } from '@/models/api/adjudication-review'
import { Procedure } from '@/models/api/adjudication'
import Recommendation from '@/components/adjudication/Recommendation.vue'

export default defineComponent({
    name: 'ReviewTable',
    components: { Recommendation },
    props: {
        procedures: {
            type: Array as PropType<Procedure[]>,
            required: true
        },
        procedureReviews: {
            type: Array as PropType<ProcedureReview[]>,
            default: () => []
        }
    },
    setup(props) {
        function getPolicyName(index: number) {
            return props.procedures[index]?.policyName
        }

        function getRecommendationStatus(index: number) {
            return props.procedures[index]?.recommendationStatus
        }

        const getApprovalStatus = (approved: boolean) => {
            return approved ? 'Approve' : 'Deny'
        }

        function hasComments(review: ProcedureReview) {
            return review.selectedCriteria || review.comments
        }

        return {
            getPolicyName,
            getRecommendationStatus,
            getApprovalStatus,
            hasComments
        }
    }
})
