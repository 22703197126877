
import { defineComponent } from 'vue'
import Spinner from '@/components/Spinner.vue'

export default defineComponent({
    name: 'TableFetchState',
    components: { Spinner },
    props: {
        message: {
            type: String,
            default: 'Fetching table data...'
        }
    }
})
