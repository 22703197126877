
import { computed, defineComponent } from 'vue'

export default defineComponent({
    name: 'RecommendationRate',
    props: {
        fraction: {
            type: Number,
            required: true
        }
    },
    setup(props) {
        const acceptancePercentage = computed(() => Math.round(props.fraction * 100))
        const declinePercentage = computed(() => 100 - acceptancePercentage.value)
        const acceptanceRateWidth = computed(() => ({ width: `${acceptancePercentage.value}%` }))
        const declineRateWidth = computed(() => ({ width: `${declinePercentage.value}%` }))

        return {
            acceptancePercentage,
            declinePercentage,
            acceptanceRateWidth,
            declineRateWidth
        }
    }
})
