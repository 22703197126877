
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'Tooltip',
    props: {
        text: {
            type: String,
            default: ''
        }
    }
})
