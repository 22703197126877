// Injection key for the object tree event bus.
export const EMITTER = Symbol('object-tree-event-bus')

export enum TreeEvent {
    // Recursively expand all of the nodes above the source node.
    EXPAND_ANCESTORS,

    // Recursively expand all of the nodes underneath the source node.
    EXPAND_DESCENDANTS,

    // Recursively collapse all of the nodes underneath the source node.
    COLLAPSE_DESCENDANTS
}
