import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "tree-property" }
const _hoisted_2 = { class: "capitalize block mb-1 text-gray-600" }
const _hoisted_3 = {
  key: 0,
  class: "overflow-scroll"
}
const _hoisted_4 = {
  key: 1,
  class: "text-sm text-gray-300"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.formattedName), 1),
    (_ctx.value !== 'null')
      ? (_openBlock(), _createElementBlock("pre", _hoisted_3, _toDisplayString(_ctx.value), 1))
      : (_openBlock(), _createElementBlock("pre", _hoisted_4, "EMPTY"))
  ]))
}