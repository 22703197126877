
import { computed, defineComponent, onBeforeMount, PropType, ref } from 'vue'
import { useStore } from 'vuex'
import { AdjudicationReview } from '@/models/api/adjudication-review'
import { PolicyCriterion, Procedure } from '@/models/api/adjudication'
import { ProcedureReview } from '@/models/view/procedure-review'
import ClinicalDetails from '@/components/adjudication/ClinicalDetails.vue'
import ProcedureCriteriaList from '@/components/adjudication/ProcedureCriteriaList.vue'
import Recommendation from '@/components/adjudication/Recommendation.vue'
import SummaryProperty from '@/components/adjudication/SummaryProperty.vue'

export default defineComponent({
    name: 'ProcedureCriteria',
    components: {
        ProcedureCriteriaList,
        ClinicalDetails,
        SummaryProperty,
        Recommendation
    },
    props: {
        procedureIndex: {
            type: Number,
            required: true
        },
        criteria: {
            type: Object as PropType<PolicyCriterion[]>,
            required: true
        },
        historical: {
            type: Boolean,
            default: false
        },
        procedure: {
            type: Object as PropType<Procedure>,
            required: true
        }
    },
    emits: ['review'],
    setup(props, context) {
        const store = useStore()
        const comments = ref('')
        const selectedCriterion = ref<string | null | undefined>(null)

        // Prepopulate with existing clinical review
        onBeforeMount(() => {
            const adjudicationReview: AdjudicationReview =
                store.state.adjudication.adjudicationReview
            const procedureReview = adjudicationReview.procedures[props.procedureIndex]
            comments.value = procedureReview.comments
            selectedCriterion.value =
                procedureReview.selectedCriteria ?? props.procedure.selectedCriteria
        })

        const procedureReview = computed(() => ({
            procedureIndex: props.procedureIndex,
            selectedCriteria: selectedCriterion.value,
            comments: comments.value
        }))

        function approveProcedure() {
            store.commit('adjudication/reviewProcedure', {
                ...procedureReview.value,
                approved: true
            } as ProcedureReview)

            context.emit('review')
        }

        function declineProcedure() {
            store.commit('adjudication/reviewProcedure', {
                ...procedureReview.value,
                approved: false
            } as ProcedureReview)

            context.emit('review')
        }

        function formatApproval(approved: boolean): string {
            if (approved) {
                return 'Approve'
            } else {
                return 'Deny'
            }
        }

        return {
            selectedCriterion,
            comments,
            approveProcedure,
            declineProcedure,
            formatApproval
        }
    }
})
