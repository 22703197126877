
import { PropType, defineComponent } from 'vue'
import {SummaryPolicy} from "@/models/api/adjudication";
import PolicyListItem from "./PolicyListItem.vue";

export default defineComponent({
    name: 'PolicyList',
    components: {PolicyListItem},
    props: {
        policies: {
            type: Array as PropType<SummaryPolicy[]>,
            default: () => ([])
        }
    }
})
