import { logout } from '@/auth'

interface State {
    user: UserState | null
}

interface UserState {
    username: string
    token: string
    clientId: string
    roles: string[]
}

const state = {
    user: {
        username: '',
        token: '',
        clientId: '',
        roles: []
    }
}

const actions = {
    async logout({ commit }: { commit: Function }) {
        commit('logout')
        return logout()
    }
}

const getters = {
    currentUser: (state: State) => state.user,
    clientId: (state: State) => state.user?.clientId,
    isAdmin: (state: State) => {
        const userRoles = state.user?.roles ?? []
        return userRoles.includes('admin')
    },
    isDisabled: (state: State) => {
        const userRoles = state.user?.roles ?? []
        const enabledRoles = ['user', 'admin']
        return enabledRoles.every(role => !userRoles.includes(role))
    }
}

const mutations = {
    login(state: State, user: UserState) {
        state.user = user
    },
    logout(state: State) {
        state.user = null
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
