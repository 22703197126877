
import { computed, defineComponent } from 'vue'
import { useStore } from 'vuex'
import ReviewTable from '@/components/adjudication/review/ReviewTable.vue'

export default defineComponent({
    name: 'AdjudicationReview',
    components: { ReviewTable },
    emits: ['close'],
    setup(_, context) {
        const store = useStore()

        const adjudication = computed(() => {
            return store.getters['adjudication/selectedAdjudication']
        })

        const adjudicationReview = computed(() => {
            return store.getters['adjudication/completedAdjudicationReview']
        })

        function cancelReview() {
            context.emit('close')
        }

        function submitReview() {
            store.dispatch('adjudication/updateAdjudication')
            context.emit('close')
        }

        return {
            adjudication,
            adjudicationReview,
            submitReview,
            cancelReview
        }
    }
})
