import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "table-fetch-state font-bold p-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.message) + " ", 1),
    _createVNode(_component_Spinner, { class: "inline ml-1" })
  ]))
}