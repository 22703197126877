
import { computed, defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import TreeRoot from '@/components/object-tree/TreeRoot.vue'

export default defineComponent({
    name: 'ClinicalDetails',
    components: { TreeRoot },
    setup() {
        const store = useStore()
        const details = computed(
            () => store.getters['adjudication/selectedAdjudication'].clinicalSummary?.details ?? {}
        )

        const tree = ref<InstanceType<typeof TreeRoot> | null>(null)
        const expandAll = () => tree.value?.expandAll()
        const collapseAll = () => tree.value?.collapseAll()

        return {
            details,
            tree,
            expandAll,
            collapseAll
        }
    }
})
