
import { defineComponent, provide } from 'vue'
import { nanoid } from 'nanoid'
import mitt from 'mitt'
import { EMITTER, TreeEvent } from './events'
import TreeObject from './TreeObject.vue'

export default defineComponent({
    name: 'TreeRoot',
    components: { TreeObject },
    props: {
        name: {
            type: String,
            required: false
        },
        subtitle: {
            type: String,
            required: false
        },
        value: {
            type: Object,
            required: true
        }
    },
    setup() {
        const id = nanoid()
        const eventBus = mitt()
        provide(EMITTER, eventBus)

        function expandAll() {
            eventBus.emit(id, TreeEvent.EXPAND_DESCENDANTS)
        }

        function collapseAll() {
            eventBus.emit(id, TreeEvent.COLLAPSE_DESCENDANTS)
        }

        return {
            id,
            expandAll,
            collapseAll
        }
    }
})
