<template>
    <div class="flex" style="min-height: 95vh;">
        <div id="nav" class="flex flex-col border-r-2 px-0">
            <div class="border-b-2 w-32 py-2.5" style="padding-left: 0.45rem; padding-right: 0.45rem;">
                <img alt="Myndshft Logo" src="./assets/myndshft.png" />
            </div>
            <router-link class="py-5" to="/">
                <i class="flex justify-center items-center fas fa-4x fa-home"></i>
            </router-link>
            <router-link class="py-5" to="/adjudications">
                <i
                    class="flex justify-center items-center justify-self-center fas fa-4x fa-clipboard-list"
                ></i>
            </router-link>
            <router-link class="py-5" to="/historical">
                <i
                    class="flex justify-center items-center justify-self-center fas fa-4x fa-clipboard-check"
                ></i>
            </router-link>
            <router-link v-if="isAdmin" class="py-5" to="/protocol">
                <i
                    class="flex justify-center items-center justify-self-center fas fa-4x fa-heartbeat"
                ></i>
            </router-link>
            <router-link v-if="isAdmin" class="py-5" to="/management">
                <i
                    class="flex justify-center items-center justify-self-center fas fa-4x fa-user"
                ></i>
            </router-link>
        </div>
        <div class="flex flex-grow flex-col w-full">
            <div class="pt-7 pb-7 px-6 border-b-2 form-group">
                <button
                    @click="logout"
                    id="logout"
                    class="mt-1 float-right fas fa-lg fa-sign-out-alt"
                ></button>
                <p class="float-right px-2 ">Welcome, {{ user?.username }}</p>
            </div>
            <router-view />
        </div>
    </div>
    <footer class="border-t-2">
        <p class="pt-2 font-bold text-center">
            &#169; Myndshft Technologies, Inc. All Rights Reserved
        </p>
    </footer>
</template>

<script>
import { mapGetters, mapActions, mapMutations, mapState } from 'vuex'

export default {
    name: 'app',
    computed: {
        ...mapState('account', ['user']),
        ...mapGetters('account', ['isAdmin'])
    },
    methods: {
        ...mapActions('account', ['logout']),
        ...mapMutations('account', ['login'])
    }
}
</script>

<style lang="scss">
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
}

#nav {
    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
</style>
