import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5681b804"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value"]
const _hoisted_2 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("select", {
    ref: "selectElement",
    onChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.selectOption($event.target.value))),
    value: _ctx.value,
    class: _normalizeClass(_ctx.classes)
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option) => {
      return (_openBlock(), _createElementBlock("option", {
        value: option.value,
        key: option.value
      }, _toDisplayString(option.name), 9, _hoisted_2))
    }), 128))
  ], 42, _hoisted_1))
}