
import { defineComponent, onBeforeMount, ref } from 'vue'
import StatisticCard from '@/components/dashboard/statistic-card.vue'
import RecommendationRate from '@/components/dashboard/recommendation-rate.vue'
import { fetchDashboardStatistics } from '@/modules/dashboard/api'
import { AdjudicationStatistics } from '@/models/api/adjudication-statistics'

export default defineComponent({
    name: 'Dashboard',
    components: {
        RecommendationRate,
        StatisticCard
    },
    setup() {
        const statistics = ref<AdjudicationStatistics | null>(null)

        onBeforeMount(async () => {
            try {
                statistics.value = await fetchDashboardStatistics()
            } catch (e) {
                alert('Unable to fetch adjudication statistics at this time')
            }
        })

        return { statistics }
    }
})
