import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "summary-property mb-2" }
const _hoisted_2 = { class: "property-name mr-2 px-3 bg-gray-200 rounded-md border-2 text-sm justify-left" }
const _hoisted_3 = { class: "property-value mr-4 text-sm" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.name), 1),
    _createElementVNode("span", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, () => [
        _createTextVNode(_toDisplayString(_ctx.value), 1)
      ])
    ])
  ]))
}