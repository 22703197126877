import { resolveComponent as _resolveComponent, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("span", {
    class: "relative cursor-help",
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showPolicyName = true)),
    onMouseout: _cache[1] || (_cache[1] = ($event: any) => (_ctx.showPolicyName = false))
  }, [
    _withDirectives(_createVNode(_component_Tooltip, {
      text: _ctx.policy.name
    }, null, 8, ["text"]), [
      [_vShow, _ctx.showPolicyName]
    ]),
    _createTextVNode(" " + _toDisplayString(_ctx.policy.id), 1)
  ], 32))
}