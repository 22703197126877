
import { defineComponent } from 'vue'

export default defineComponent({
    name: 'StatisticCard',
    props: {
        label: {
            type: String,
            required: true
        },
        value: {
            type: Number,
            required: true
        }
    }
})
