import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as auth from './auth'
import './main.css'
import interceptorsSetup from './modules/config/interceptors'

interceptorsSetup()

auth.init().then(() => {
    createApp(App)
        .use(store)
        .use(router)
        .mount('#app')
})
