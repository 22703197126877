
import { computed, defineComponent } from 'vue'

export default defineComponent({
    name: 'TreeProperty',
    props: {
        name: {
            type: String,
            required: true
        },
        value: {
            type: String,
            required: false
        }
    },
    setup(props) {
        // Convert camelCase keys to Title Case.
        const formattedName = computed(() => {
            return props.name
                .replace(/((?<![A-Z])[A-Z])/g, ' $1')
                .replace(/^./, s => s.toUpperCase())
                .trim()
        })

        return { formattedName }
    }
})
