import { Ord, contramap, ordString, getDualOrd } from 'fp-ts/Ord'
import * as t from 'io-ts'

export const SummaryPolicy = t.type({
    id: t.union([t.string, t.null]),
    name: t.union([t.string, t.null])
})

export type SummaryPolicy = t.TypeOf<typeof SummaryPolicy>

export const AdjudicationSummary = t.type({
    referenceNumber: t.string,
    patientName: t.string,
    providerName: t.string,
    policies: t.array(t.union([SummaryPolicy, t.null])),
    createdDate: t.string,
    updatedDate: t.string
})

export type AdjudicationSummary = t.TypeOf<typeof AdjudicationSummary>

export const AdjudicationSummaryList = t.array(AdjudicationSummary)

export type AdjudicationSummaryList = t.TypeOf<typeof AdjudicationSummaryList>

export type ISO8601Timestamp = string

export const byChronologicalOrder: Ord<ISO8601Timestamp> = ordString

export const byReverseChronologicalOrder: Ord<ISO8601Timestamp> = getDualOrd(byChronologicalOrder)

export const byCreatedDate = contramap<ISO8601Timestamp, AdjudicationSummary>(
    adjudication => adjudication.createdDate
)

export const byUpdatedDate = contramap<ISO8601Timestamp, AdjudicationSummary>(
    adjudication => adjudication.updatedDate
)

export const Frequency = t.type({
    value: t.number,
    type: t.string
})

export type Frequency = t.TypeOf<typeof Frequency>

export const Period = t.type({
    start: t.string,
    end: t.string
})

export type Period = t.TypeOf<typeof Period>

export const Policy = t.type({
    id: t.string,
    name: t.string,
    url: t.string,
    approvedCriteria: t.union([t.string, t.null]),
    otherCriteria: t.union([t.array(t.string), t.null])
})

export type Policy = t.TypeOf<typeof Policy>

export const PolicyCriterion = t.type({
    name: t.string,
    detail: t.string,
    evaluated: t.boolean
})

export type PolicyCriterion = t.TypeOf<typeof PolicyCriterion>

export const Procedure = t.type({
    id: t.string,
    code: t.string,
    approvalFrequencyValue: t.union([t.number, t.null]),
    approvalFrequencyType: t.union([t.string, t.null]),
    policyId: t.union([t.string, t.null]),
    policyName: t.union([t.string, t.null]),
    policyUrl: t.union([t.string, t.null]),
    recommendationStatus: t.string,
    recommendationStatusReason: t.union([t.string, t.null]),
    criteria: t.array(PolicyCriterion),
    comments: t.union([t.string, t.null]),
    approved: t.union([t.boolean, t.null]),
    selectedCriteria: t.union([t.string, t.null, t.undefined])
})

export type Procedure = t.TypeOf<typeof Procedure>

export const ClinicalSummary = t.type({
    createdDate: t.string,
    recommendationStatus: t.string,
    authorizationId: t.string,
    comments: t.union([t.string, t.null]),
    status: t.union([t.string, t.null]),
    period: t.union([Period, t.null]),
    details: t.union([t.UnknownRecord, t.null])
})

export type ClinicalSummary = t.TypeOf<typeof ClinicalSummary>

export const Adjudication = t.type({
    clinicalSummary: ClinicalSummary,
    procedures: t.array(Procedure)
})

export type Adjudication = t.TypeOf<typeof Adjudication>
