
import { useStore } from 'vuex'
import * as array from 'fp-ts/Array'
import {
    AdjudicationSummaryList,
    SummaryPolicy,
    byChronologicalOrder,
    byReverseChronologicalOrder,
    byCreatedDate,
    byUpdatedDate
} from '@/models/api/adjudication'
import { defineComponent, computed, ref } from 'vue'
import PolicyList from '@/components/adjudication/PolicyList.vue'
import { confirm } from '@/modules/alerts'

export default defineComponent({
    name: 'AdjudicationSummary',
    components: { PolicyList },
    props: {
        historical: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const store = useStore()

        store.dispatch('adjudication/fetchAdjudications', props.historical)

        const isReviewInProgress = computed(() => {
            return store.getters['adjudication/isReviewInProgress']
        })

        const allAdjudications = computed<AdjudicationSummaryList>(
            () => store.state.adjudication.adjudications
        )

        const selectedAuthorizationId = computed(
            () => store.getters['adjudication/selectedAdjudicationId']
        )

        function sortByDate(adjudications: AdjudicationSummaryList): AdjudicationSummaryList {
            const totalOrdering = props.historical
                ? byUpdatedDate(byReverseChronologicalOrder)
                : byCreatedDate(byChronologicalOrder)

            return array.sort(totalOrdering)(adjudications)
        }

        const authIdFilter = ref('')
        const patientFilter = ref('')
        const providerFilter = ref('')
        const policyFilter = ref('')
        const dateFilter = ref('')

        const filteredAdjudications = computed(() => {
            let adjudications = sortByDate(allAdjudications.value)

            if (authIdFilter.value) {
                adjudications = adjudications.filter(adjudication =>
                    adjudication.referenceNumber
                        .toLowerCase()
                        .includes(authIdFilter.value.toLowerCase())
                )
            }

            if (patientFilter.value) {
                adjudications = adjudications.filter(adjudication =>
                    adjudication.patientName
                        .toLowerCase()
                        .includes(patientFilter.value.toLowerCase())
                )
            }

            if (providerFilter.value) {
                adjudications = adjudications.filter(adjudication =>
                    adjudication.providerName
                        .toLowerCase()
                        .includes(providerFilter.value.toLowerCase())
                )
            }

            if (policyFilter.value) {
                adjudications = adjudications.filter(adjudication => {
                    return adjudication.policies
                        .map(p => p?.id ?? '')
                        .join('')
                        .toLowerCase()
                        .includes(policyFilter.value.toLowerCase())
                })
            }

            if (dateFilter.value) {
                adjudications = adjudications.filter(adjudication =>
                    adjudication.createdDate.toLowerCase().includes(dateFilter.value.toLowerCase())
                )
            }

            for (let i = 0; i < adjudications.length; i++) {
                const date = new Date(adjudications[i].createdDate)
                adjudications[i].createdDate =
                    (date.getMonth() > 8 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1)) +
                    '/' +
                    (date.getDate() > 9 ? date.getDate() : '0' + date.getDate()) +
                    '/' +
                    date.getFullYear()
            }
            return adjudications
        })

        function filterPolicies(policies: SummaryPolicy[] | null) {
            const policyIdSet = new Set()
            const policySet: SummaryPolicy[] = []

            policies?.forEach(p => {
                if (p.id != null && !policyIdSet.has(p.id)) {
                    policySet.push(p)
                    policyIdSet.add(p.id)
                }
            })

            return policySet
        }

        async function selectAdjudication(id: string) {
            if (
                !isReviewInProgress.value ||
                props.historical ||
                (await confirm('Do you want to discard the review in progress?'))
            ) {
                store.dispatch('adjudication/fetchAdjudication', id)
            }
        }

        return {
            authIdFilter,
            patientFilter,
            providerFilter,
            policyFilter,
            dateFilter,
            selectedAuthorizationId,
            selectAdjudication,
            filteredAdjudications,
            filterPolicies
        }
    }
})
