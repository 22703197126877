<template>
    <Expandable ref="expandable" :title="name" subtitle="list">
        <template v-if="value.length > 0">
            <!-- Array of Literals -->
            <template v-if="typeof value[0] === 'string'">
                <li v-for="(item, i) of value" :key="i" class="mb-2 font-mono">
                    {{ item }}
                </li>
            </template>

            <!-- Array of Objects -->
            <TreeObject
                v-else
                v-for="(v, i) of value"
                :parent="qualifiedId"
                :name="String(i + 1)"
                subtitle="item"
                :value="v"
                :key="`${name} #${i}`"
            />
        </template>
        <div v-else class="text-sm text-gray-300">EMPTY</div>
    </Expandable>
</template>

<script>
import { computed, defineAsyncComponent, defineComponent, inject, ref } from 'vue'
import { nanoid } from 'nanoid'
import { EMITTER, TreeEvent } from './events'
import Expandable from './Expandable.vue'

export default defineComponent({
    name: 'TreeArray',
    components: {
        Expandable,
        // TreeObject is imported asynchronously because it is an indirect
        // circular dependency. TreeArray and TreeObject are simultaneously
        // each other's descendant and ancestor in the render tree.
        TreeObject: defineAsyncComponent(() => import('./TreeObject.vue'))
    },
    props: {
        parent: {
            type: String,
            required: true
        },
        name: {
            type: String,
            required: true
        },
        value: {
            type: Array,
            required: true
        }
    },
    setup(props) {
        const id = nanoid()
        const qualifiedId = computed(() => [props.parent, id].join('.'))
        const eventBus = inject(EMITTER)
        const expandable = ref(null)

        eventBus?.on('*', (source, action) => {
            if (qualifiedId.value?.includes(source)) {
                switch (action) {
                    case TreeEvent.EXPAND_DESCENDANTS:
                        expandable.value?.expand()
                        break

                    case TreeEvent.COLLAPSE_DESCENDANTS:
                        expandable.value?.collapse()
                        break
                }
            }
        })

        return {
            expandable,
            qualifiedId
        }
    }
})
</script>
