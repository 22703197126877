
import { useStore } from 'vuex'
import { computed, defineComponent, ref, watch } from 'vue'
import { format } from 'date-fns'
import { Adjudication, Period } from '@/models/api/adjudication'
import SubmissionInformation from '@/components/adjudication/SubmissionInformation.vue'
import ProcedureSummaryList from '@/components/adjudication/ProcedureSummaryList.vue'
import Modal from '@/components/Modal.vue'
import ClinicalDetails from '@/components/adjudication/ClinicalDetails.vue'
import ReviewStatus from '@/components/adjudication/review/ReviewStatus.vue'

function formatDate(d: string) {
    return format(new Date(d), 'MM/dd/yyyy')
}

function formatPeriod(period: Period): string {
    try {
        return `${formatDate(period.start)} - ${formatDate(period.end)}`
    } catch {
        return 'N/A'
    }
}

export default defineComponent({
    name: 'ClinicalSummary',
    components: {
        ReviewStatus,
        ClinicalDetails,
        Modal,
        ProcedureSummaryList,
        SubmissionInformation
    },
    props: {
        historical: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const store = useStore()

        const comments = computed({
            get: () => {
                return store.state.adjudication.adjudicationReview.comments
            },
            set: (comments: string) => {
                store.commit('adjudication/setAdjudicationComments', comments)
            }
        })

        const adjudication = computed<Adjudication | null>(() => {
            return store.getters['adjudication/selectedAdjudication']
        })
        const procedures = computed(() => adjudication.value?.procedures ?? [])
        const proceduresReviewed = computed(() => {
            return store.getters['adjudication/proceduresReviewed']
        })
        const procedureCount = computed(() => {
            return store.getters['adjudication/procedureCount']
        })

        watch(adjudication, adjudication => {
            if (props.historical) {
                comments.value = adjudication?.clinicalSummary.comments || 'No comments'
            }
        })

        const submissionInformation = computed(() => {
            if (adjudication.value === null) {
                return null
            }

            const {
                authorizationId,
                recommendationStatus,
                createdDate,
                period,
                status
            } = adjudication.value.clinicalSummary

            return {
                authorizationId,
                recommendationStatus,
                createdDate: formatDate(createdDate),
                approvalPeriod: period != null ? formatPeriod(period) : null,
                status
            }
        })

        const showDetails = ref(false)
        function toggleDetails() {
            showDetails.value = !showDetails.value
        }

        return {
            proceduresReviewed,
            procedureCount,
            submissionInformation,
            adjudication,
            procedures,
            comments,
            showDetails,
            toggleDetails
        }
    }
})
