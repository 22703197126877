import axios from 'axios'
import store from '../../store'

export default function setup() {
    if (process.env.NODE_ENV === 'development') {
        // Myndauth API
        axios.defaults.baseURL = 'http://localhost:8090'
    }

    axios.interceptors.request.use(function(config) {
        const user = store.state.account?.user ?? {}
        const { token, clientId, username } = user

        if (config && config.headers) {
            config.headers['Authorization'] = 'Bearer ' + token
            config.headers['X-Myndshft-Client-Id'] = clientId
            config.headers['X-Myndshft-Username'] = username
        }

        return config
    })
}
