
import { mapGetters } from 'vuex'
import AdjudicationSummary from '@/components/adjudication/AdjudicationSummary.vue'
import ClinicalSummaryStatus from '@/components/adjudication/ClinicalSummaryStatus.vue'
import store from '@/store'

export default {
    name: 'HistoricalAdjudication',
    components: {
        AdjudicationSummary: AdjudicationSummary,
        ClinicalSummaryStatus: ClinicalSummaryStatus
    },
    computed: mapGetters('adjudication', ['allAdjudications']),
    setup() {
        store.commit('adjudication/clearAdjudication')
    }
}
