import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  id: "user-management",
  class: "m-8"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RoleTable = _resolveComponent("RoleTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_RoleTable, {
      onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.updateUserRole($event))),
      users: _ctx.users
    }, null, 8, ["users"])
  ]))
}