import axios from 'axios'
import * as E from 'fp-ts/Either'
import reporter from 'io-ts-reporters'
import { Adjudication, AdjudicationSummaryList, Procedure } from '@/models/api/adjudication'
import { AdjudicationReview } from '@/models/api/adjudication-review'
import { ProcedureReview } from '@/models/view/procedure-review'
import { AlertType, toast } from '@/modules/alerts'

interface NoSelectedAdjudication {
    type: 'NULL'
}

interface FetchingAdjudication {
    type: 'FETCHING'
    id: string
}

interface LoadedAdjudication {
    type: 'LOADED'
    value: Adjudication
}

interface ErrorAdjudication {
    type: 'ERROR'
    id: string
}

export type AdjudicationState =
    | NoSelectedAdjudication
    | FetchingAdjudication
    | LoadedAdjudication
    | ErrorAdjudication

interface State {
    adjudications: AdjudicationSummaryList
    adjudication: AdjudicationState
    adjudicationReview: AdjudicationReview
    reviewStartTime: number
}

const state: State = {
    adjudications: [],
    adjudication: { type: 'NULL' },
    adjudicationReview: {
        procedures: [],
        comments: '',
        approved: null
    },
    reviewStartTime: Date.now()
}

const getters = {
    selectedAdjudicationId(state: State): string | null {
        switch (state.adjudication.type) {
            case 'NULL':
                return null
            case 'FETCHING':
                return state.adjudication.id
            case 'LOADED':
                return state.adjudication.value.clinicalSummary.authorizationId
            case 'ERROR':
                return state.adjudication.id
        }
    },

    selectedAdjudication(state: State): Adjudication | null {
        switch (state.adjudication.type) {
            case 'LOADED':
                return state.adjudication.value
            default:
                return null
        }
    },

    procedureReviewCount(state: State): number {
        return state.adjudicationReview.procedures.filter(p => p.approved !== null).length
    },

    procedureCount(state: State): number {
        return state.adjudicationReview.procedures.length
    },

    procedureReviewStatus(state: State) {
        return (index: number): boolean | null => {
            return state.adjudicationReview.procedures[index]?.approved ?? null
        }
    },

    completedAdjudicationReview(state: State, getters: any): AdjudicationReview {
        if (getters.procedureReviewCount > 0) {
            return state.adjudicationReview
        }

        const { procedures, comments, approved } = state.adjudicationReview

        return {
            procedures: procedures.map(p => ({ ...p, approved })),
            comments,
            approved
        }
    },

    isReviewInProgress(state: State, getters: any): boolean {
        return getters.procedureReviewCount > 0 || Boolean(state.adjudicationReview.comments)
    }
}

const actions = {
    async fetchAdjudications({ commit }: { commit: Function }, historical = false) {
        try {
            const response = await axios.get(`/api/v1/adjudications?historical=${historical}`)

            const responseDecoded = AdjudicationSummaryList.decode(response.data?.adjudications)

            if (E.isRight(responseDecoded)) {
                commit('setAdjudications', responseDecoded.right)
                commit('clearAdjudication')
                commit('resetAdjudicationReview')
            } else {
                console.log(reporter.report(responseDecoded))
            }
        } catch (e) {
            toast('Unable to retrieve PA requests at this time', { icon: AlertType.ERROR })
            return
        }
    },

    async fetchAdjudication(
        { commit, getters }: { commit: Function; getters: any },
        authorizationId: string
    ) {
        try {
            commit('setAdjudicationState', { type: 'FETCHING', id: authorizationId })
            const response = await axios.get(`/api/v1/adjudications/${authorizationId}`)
            const responseDecoded = Adjudication.decode(response.data)

            // Abort if the user selected a different adjudication during the network request.
            if (authorizationId !== getters.selectedAdjudicationId) {
                return
            }

            if (E.isRight(responseDecoded)) {
                commit('setAdjudicationState', { type: 'LOADED', value: responseDecoded.right })
                commit('resetAdjudicationReview')
            } else {
                commit('setAdjudicationState', { type: 'ERROR', id: authorizationId })
                console.log(reporter.report(responseDecoded))
            }
        } catch (e) {
            commit('setAdjudicationState', { type: 'ERROR', id: authorizationId })
        }
    },

    async retryFetchAdjudication({ dispatch, getters }: { dispatch: Function; getters: any }) {
        if (getters.selectedAdjudicationId) {
            dispatch('fetchAdjudication', getters.selectedAdjudicationId)
        }
    },

    async updateAdjudication({
        state,
        getters,
        dispatch
    }: {
        state: State
        getters: any
        commit: Function
        dispatch: Function
    }) {
        try {
            if (state.adjudication.type === 'LOADED') {
                const { authorizationId } = state.adjudication.value.clinicalSummary
                const review = {
                    ...getters.completedAdjudicationReview,
                    reviewTime: Date.now() - state.reviewStartTime
                }

                await axios.patch(`/api/v1/adjudications/${authorizationId}`, review)
                await dispatch('fetchAdjudications')
                toast('Adjudication review submitted!', { icon: AlertType.SUCCESS })
            }
        } catch (e) {
            toast('Unable to submit the adjudication review at this time', {
                icon: AlertType.ERROR
            })
        }
    }
}

const mutations = {
    setAdjudications(state: State, adjudications: AdjudicationSummaryList) {
        state.adjudications = adjudications
    },

    setAdjudicationState(state: State, adjudication: AdjudicationState) {
        state.adjudication = adjudication
    },

    clearAdjudication(state: State) {
        state.adjudication = { type: 'NULL' }
    },

    reviewProcedure(state: State, review: ProcedureReview) {
        const { procedureIndex, ...updatedReview } = review
        const procedureReview = state.adjudicationReview.procedures[procedureIndex]

        state.adjudicationReview.procedures[procedureIndex] = {
            ...procedureReview,
            ...updatedReview
        }
    },

    reviewAdjudication(state: State, approved: boolean) {
        state.adjudicationReview.approved = approved
    },

    setAdjudicationComments(state: State, comments: string) {
        state.adjudicationReview.comments = comments
    },

    resetAdjudicationReview(state: State) {
        let procedures: Procedure[] = []
        if (state.adjudication.type === 'LOADED') {
            procedures = state.adjudication.value.procedures
        }

        state.adjudicationReview = {
            procedures: procedures.map(p => ({
                id: p.id,
                code: p.code,
                selectedCriteria: null,
                approved: null,
                comments: '',
                policyId: p.policyId,
                recommendationStatus: p.recommendationStatus
            })),
            comments: '',
            approved: null
        }
    },

    resetReviewClock(state: State) {
        state.reviewStartTime = Date.now()
    }
}

export default {
    state,
    getters,
    actions,
    mutations
}
