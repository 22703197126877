import axios from 'axios'
import * as either from 'fp-ts/Either'
import { KeycloakUserList, KeycloakUserUpdate } from '@/models/api/user-management'
import { ActionContext } from 'vuex'
import { KeycloakUser } from '@/models/view/user-management'
import { AlertType, toast } from '@/modules/alerts'

interface UserUpdateActionPayload {
    id: string
    update: KeycloakUserUpdate
}

interface State {
    users: KeycloakUserList
}

const state: State = {
    users: []
}

const getters = {
    users(state: State): KeycloakUser[] {
        return state.users.map(user => {
            const { id, firstName, lastName, role, username } = user
            const fullName = [firstName, lastName].filter(Boolean).join(' ') || 'N/A'

            return {
                id,
                fullName,
                email: username || 'N/A',
                role: role || 'disabled'
            }
        })
    }
}

const mutations = {
    setUsers(state: State, users: KeycloakUserList) {
        state.users = users
    }
}

const actions = {
    async fetchUsers(context: ActionContext<State, any>) {
        const response = await axios.get('/api/v1/users')
        const users = KeycloakUserList.decode(response.data)

        if (either.isRight(users)) {
            context.commit('setUsers', users.right)
        }
    },

    async updateUser(context: ActionContext<State, any>, payload: UserUpdateActionPayload) {
        const { id, update } = payload

        try {
            await axios.patch(`api/v1/users/${id}`, update)
            await context.dispatch('fetchUsers')
            toast('Updated user role!', { icon: AlertType.SUCCESS })
        } catch {
            toast('Unable to update the user role at this time', { icon: AlertType.ERROR })
        }
    }
}

export default {
    state,
    getters,
    mutations,
    actions
}
